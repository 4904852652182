import { refInvestProfile } from '@p/refenums';
import { pRound, stringToNum } from '@p/utils';
import dayjs from 'dayjs';
import type { IRetr } from './retrBaseReco.calc';
import { CalcRetrBaseReco } from './retrBaseReco.calc';
import { RetrInputRecoVers } from './retrInputRecovers.calc';
import { RetrOutputEl } from './retrOutputEl.calc';

const needDefaultValue: Required<IRetr['need']> = {
  transfertVers: 0,
  initialVers: 0,
  regularVers: [0 as TypedMonthly],
  evolvingVers: false,
  investProfile: 'balanced',
  rate: refInvestProfile.balanced.rate!,
  persoInvestComment: '',
};

export class CalcRetrOutput extends CalcRetrBaseReco {
  private readonly tmi: number;
  private readonly need: Required<IRetr['need']>;
  private readonly endResults: ReturnType<typeof RetrOutputEl['getResults']>;
  private readonly addAmountToCoverNeed!: RetrInputRecoVers | false;

  constructor(customer?: PMain.FilteredCustomer) {
    super(customer);

    this.tmi =
      (customer?.Retr?.questions.tmi ?? customer?.profile.revenue.tmi ?? 0) /
      100;
    this.need = { ...needDefaultValue, ...customer?.Retr!.need };
    this.endResults = this.calcEndResults();
    this.addAmountToCoverNeed = this.calcAddAmount();
  }

  // ! ADD AMOUNT
  private calcAddAmount() {
    const addRequiredRente = ((this.questions.addNeedRetrAmount ?? 0) -
      this.endResults.rente) as TypedMonthly;
    if (addRequiredRente < 100) return false;
    const { commonInitElInput, recoInvestProfileAndRate } = this;
    const initEl = {
      ...commonInitElInput,
      initialVers: 0,
      transfertVers: 0,
      ...recoInvestProfileAndRate,
    };
    return new RetrInputRecoVers(addRequiredRente, false, initEl);
  }

  public get addVersToFullRetr() {
    if (!this.addAmountToCoverNeed) return 0;
    return pRound(this.addAmountToCoverNeed.regularVersFromRente, -1);
  }

  // ! CURRENT YEAR

  public get currentYearTaxSavings() {
    return pRound(this.tmi * this.amountForTaxSavings, -2);
  }

  private get currentYearTotalVersWithoutTransfer() {
    return (
      this.nbRegularVersMonths * this.regularVersY1 + this.need.initialVers
    );
  }

  private get madelinRetrTotal() {
    const { madelinRetrCurrent, madelinRetrOther } = this.questions;

    return (madelinRetrCurrent ?? 0) + (madelinRetrOther ?? 0);
  }

  private get amountForTaxSavings() {
    return this.begYearIsThisYear
      ? this.amountForTaxSavingsOneYear
      : this.amountForTaxSavingsTwoYears;
  }

  private get amountForTaxSavingsOneYear() {
    return Math.min(
      this.currentYearTotalVersWithoutTransfer,
      this.madelinRetrTotal,
    );
  }

  private get amountForTaxSavingsTwoYears() {
    return (
      Math.min(
        this.nbRegularVersMonths * this.regularVersY1,
        this.madelinRetrTotal,
      ) + Math.min(this.need.initialVers, this.madelinRetrTotal)
    );
  }

  private get amountForTaxSavingsRegular() {
    return (
      Math.min(
        (this.questions.madelinRetrCurrent ?? 0) / 12,
        this.regularVersY1 ?? 0,
      ) * this.nbRegularVersMonths
    );
  }

  private get regularVersY1() {
    return this.need.regularVers[0] ?? 0;
  }

  // ! END
  private get defaultRounding() {
    return -2;
    // return this.need.regularVers[2] ? -3 : -2;
  }

  public get endCapital() {
    return pRound(this.endResults.endCapital, -2);
  }

  private get endVersWithTransfer() {
    return this.endResults.totalVers;
  }

  public get endVers() {
    return pRound(this.endVersWithTransfer, this.defaultRounding);
  }

  private get totalMadelinVersWithoutInitial() {
    return this.endResults.totalMadelinVers;
  }

  public get endTaxSavings() {
    const totalAmountForTaxSavings =
      this.totalMadelinVersWithoutInitial +
      this.amountForTaxSavings -
      this.amountForTaxSavingsRegular;
    return pRound(this.tmi * totalAmountForTaxSavings, this.defaultRounding);
  }

  public get monthlyRetr() {
    return pRound(this.endResults.rente, -1);
  }

  public get nbYears() {
    return 67 - this.currentAge;
  }

  private get currentAge() {
    return stringToNum(dayjs().format('YYYY')) - this.birthYear;
  }

  private calcEndResults() {
    const { need, commonInitElInput } = this;
    const { transfertVers, initialVers, regularVers, investProfile, rate } =
      need;
    return RetrOutputEl.getResults({
      ...commonInitElInput,
      transfertVers,
      initialVers,
      regularVers,
      investProfile,
      rate,
    });
  }
}
