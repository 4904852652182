import { GeneralError } from '@feathersjs/errors';
import type { refInvestProfileKeys } from '@p/refenums';
import { refInvestProfile } from '@p/refenums';
import { consoleObject, lastEl, nbAry, sumAry } from '@p/utils';
import dayjs from 'dayjs';
import { ASSUVIE_DEFAULT_ENTRY_FEES } from '../constants';

export interface IInitialEl {
  endMonth: Date;
  begMonth: Date;
  transfertVers: number;
  initialVers: number;
  regularVers: number[];
  investProfile: typeof refInvestProfileKeys[number];
  rate: number;
  entryFees?: number;
  promis: boolean;
}

const interestsPartOnRegVers = (n: number) => {
  const nAry = nbAry(n);
  return sumAry(nAry.map((i) => (n - i) / 12)) / 12;
};

const interestsPartOnRegVersFullYear = interestsPartOnRegVers(12);

interface IElRes {
  nextBegMonth: Date;
  endCapital: number;
  regMonthlyVers: number;
  totalVers: number;
  i: number;
  isLastPeriod: boolean;
}
export class EparOutputEl {
  public i: number;
  public res!: IElRes;

  static getResults(initEl: IInitialEl, logValues = false) {
    const resAry = this.build(initEl, [], 0, logValues);

    const endRes = lastEl(resAry) ?? {
      endCapital: 0,
      totalVers: 0,
    };
    return { ...endRes };
  }

  private static build(
    initEl: IInitialEl,
    previousAry: IElRes[],
    i: number,
    logValues = false,
  ): IElRes[] {
    if (i > 60) throw new GeneralError('Recursion Error in EparOuputEl Build');
    const newEl = new this(initEl, lastEl(previousAry));
    // eslint-disable-next-line ban/ban
    if (logValues) consoleObject(newEl.previousEl ?? newEl.initEl);
    const newAry = [...previousAry, newEl.res];
    if (newEl.isLastPeriod) return newAry;
    return this.build(initEl, newAry, i + 1, logValues);
  }

  private constructor(
    private readonly initEl: IInitialEl,
    private readonly previousEl?: IElRes,
  ) {
    this.i = this.isInitial ? 0 : this.previousEl!.i + 1;
    this.calcResults();
  }

  private calcResults() {
    const {
      nextBegMonth,
      endCapital,
      regMonthlyVers,
      endVers: totalVers,
      i,
      isLastPeriod,
    } = this;
    this.res = {
      nextBegMonth: nextBegMonth.toDate(),
      endCapital,
      regMonthlyVers,
      totalVers,
      i,
      isLastPeriod,
    };
  }

  private get isInitial() {
    return !this.previousEl;
  }

  // ! DATES
  private get endMonth() {
    return dayjs(this.initEl.endMonth);
  }

  private get begMonth() {
    const begMonthDate = this.isInitial
      ? this.initEl.begMonth
      : this.previousEl!.nextBegMonth;
    return dayjs(begMonthDate);
  }

  private get nextBegMonth() {
    return this.begMonth.add(1, 'year');
  }

  private get isLastPeriod() {
    return this.endMonth < this.nextBegMonth;
  }

  // ! VERS
  private get nbRegularVers() {
    if (!this.isLastPeriod) return 12;
    return this.endMonth.diff(this.begMonth, 'months') + 1;
  }

  private get entryCoeff() {
    return 1 - (this.initEl.entryFees ?? ASSUVIE_DEFAULT_ENTRY_FEES) / 100;
  }

  private get begVers() {
    return this.isInitial
      ? this.initEl.transfertVers + this.initEl.initialVers
      : this.previousEl!.totalVers;
  }

  private get endVers() {
    return this.begVers + this.totalRegVersRaw;
  }

  private get totalRegVersAfterEntryFee() {
    return this.totalRegVersRaw * this.entryCoeff;
  }

  private get regMonthlyVers() {
    if (this.isInitial) return this.initEl.regularVers[0] ?? 0;
    return this.initEl.regularVers[this.i] ?? this.previousEl!.regMonthlyVers;
  }

  private get totalRegVersRaw() {
    return this.nbRegularVers * this.regMonthlyVers;
  }

  // ! BEGINING CAPITAL
  private get begCapital() {
    return this.isInitial
      ? this.initEl.transfertVers + this.initEl.initialVers * this.entryCoeff
      : this.previousEl!.endCapital;
  }

  // ! INTERESTS
  private get interestsOnRegVers() {
    const n = this.nbRegularVers;
    if (!this.isLastPeriod)
      return (
        interestsPartOnRegVersFullYear *
        this.currentRate *
        this.totalRegVersAfterEntryFee
      );
    return (
      interestsPartOnRegVers(n) *
      this.currentRate *
      this.totalRegVersAfterEntryFee
    );
  }

  private get interestsOnBegCapital() {
    return this.begCapital * this.currentRate * (this.nbRegularVers / 12);
  }

  // ! RATE
  private get currentRate() {
    if (this.initEl.promis) {
      if (this.initEl.investProfile === 'perso') return this.initEl.rate / 100;
      return refInvestProfile[this.initEl.investProfile].rate! / 100;
    }

    return this.initEl.rate / 100;
  }

  // ! END CAPITAL

  private get endCapital() {
    return (
      this.begCapital +
      this.interestsOnBegCapital +
      this.totalRegVersAfterEntryFee +
      this.interestsOnRegVers
    );
  }
}
