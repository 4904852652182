import { PASS } from '../constants';

// const MICRO_BNC_FRANCHISE = 0.34 as const;
// const MICRO_ENT_CHARGES = 0.22 as const;

// export const coeffCAtoNet = (microEnt = false, microBnc = false) => {
//   if (microEnt) return 1 - MICRO_ENT_CHARGES;
//   if (microBnc)
//     return (
//       (1 - MICRO_BNC_FRANCHISE) / (1 + CHARGES_LIBERAL) + MICRO_BNC_FRANCHISE
//     );
//   return 1 / (1 + CHARGES_LIBERAL);
// };

// export const calcNetRevenueFromCA = <T extends number>(
//   caNet: T = 0 as T,
//   microEnt = false,
//   microBnc = false,
// ) => (caNet * coeffCAtoNet(microEnt, microBnc)) as T;

// ! MADELIN
export const calcMadelinPrevSante = (
  revNet: TypedNumber<'year'>,
  micro = false,
) => {
  if (micro) return 0 as TypedNumber<'year'>;
  const nominal = 0.0375 * revNet + 0.07 * PASS;
  const maxValue = 0.08 * 8 * PASS;
  return Math.min(nominal, maxValue) as TypedNumber<'year'>;
};

export const calcMadelinRetr = (revNet: TypedNumber<'year'>, micro = false) => {
  const minValue = 0.1 * PASS;
  const minRev8Pass = Math.min(revNet, 8 * PASS);
  const nominal = 0.1 * minRev8Pass;
  const complement = micro ? 0 : Math.max(0.15 * (minRev8Pass - PASS), 0);
  return Math.max(minValue, nominal + complement) as TypedNumber<'year'>;
};

// ! IMPOTS
export const calcNbParts = (inCouple = false, nbChildren = 0) => {
  const adultsParts = 1 + (inCouple ? 1 : 0);
  const childrenParts = nbChildren > 2 ? 1 + nbChildren - 2 : nbChildren * 0.5;
  return adultsParts + childrenParts;
};

const TMI_GRID = [
  [153_783, 45 as TypedNumber<'percent'>],
  [72_617, 41 as TypedNumber<'percent'>],
  [27_086, 30 as TypedNumber<'percent'>],
  [9807, 13 as TypedNumber<'percent'>],
] as const;

export const calcTmiPercent = (
  revNetFamily: TypedNumber<'year'> = 0 as TypedNumber<'year'>,
  nbParts = 1,
) => {
  const tmiEl = TMI_GRID.find((el) => revNetFamily / nbParts > el[0]);
  if (tmiEl) return tmiEl[1];
  return 0 as TypedNumber<'percent'>;
};
