/* eslint-disable @typescript-eslint/naming-convention */
import modeleSocialRules from 'modele-social';

const correctedRules = {
  "entreprise . durée d'activité . en fin d'année": {
    titre: "durée d'activité à la fin de l'année",
    nom: "entreprise . durée d'activité . en fin d'année",
    formule: {
      somme: [
        {
          durée: {
            depuis: 'date de création',
            "jusqu'à": "période . fin d'année",
          },
        },
        '-1 jour',
      ],
    },
  },
  // 'dirigeant . rémunération . totale': {
  //   titre: 'Rémunération dirigeant',
  //   nom: 'dirigeant . rémunération . totale',
  //   'par défaut': "entreprise . chiffre d'affaires - entreprise . charges",
  // },
};
// "par défaut": "promis . net + promis . net . correction",

const promisGeneralRules = {
  promis: {
    titre: 'valeurs spécifiques Promis',
    nom: 'promis',
    // valeur: 'oui',
  },
  'promis . net . correction': {
    titre: 'correction du revenu',
    nom: 'promis . net . correction',
    unité: '€/an',
    'par défaut': 0,
  },
  'promis . net': {
    titre: 'revenu net pour les simulations Promis',
    nom: 'promis . net',
    unité: '€ / an',
    arrondi: 'oui',
    formule: {
      somme: [
        {
          variations: [
            {
              si: "entreprise . catégorie juridique . EI . auto-entrepreneur = 'oui'",
              alors: 'dirigeant . auto-entrepreneur . net de cotisations',
            },
            {
              sinon: 'dirigeant . rémunération . nette',
            },
          ],
        },
        '(- correction)',
      ],
    },
    plancher: 0,
  },
};

export const getConsolidatedRules = () => {
  const rules = {
    ...modeleSocialRules,
    ...correctedRules,
    ...promisGeneralRules,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any;
  rules['dirigeant . rémunération . nette']['par défaut'] =
    'promis . net + promis . net . correction';
  return rules;
};
