import { refInvestProfile } from '@p/refenums';
import { begDateDefault, pRound } from '@p/utils';
import dayjs from 'dayjs';
import { LDD_RATE, LIVRET_A_RATE, PEL_RATE } from '../constants';
import { EparOutputEl } from './eparOutputEl.calc';

const needDefaultValue: Required<IEpar['need']> = {
  transfertVers: 0,
  initialVers: 0,
  regularVers: [0 as TypedMonthly],
  evolvingVers: false,
  investProfile: 'balanced',
  rate: refInvestProfile.balanced.rate!,
  persoInvestComment: '',
};

const questionsDefaultValue: Required<IEpar['questions']> = {
  retrComplementNeed: 0 as TypedMonthly,
  eparMonthlyTransfertNeed: 0 as TypedMonthly,
  eparInitTransfertNeed: 0,
  eparNbYears: 8,
  begDate: begDateDefault().toDate(),
};

export type IEpar = NonNullable<PMain.CustomerModel['Epar']>;

export class CalcEparOutput {
  private readonly need: Required<IEpar['need']>;
  private readonly endResults: ReturnType<typeof EparOutputEl['getResults']>;
  private readonly endResultsLivretA: ReturnType<
    typeof EparOutputEl['getResults']
  >;

  private readonly endResultsLDD: ReturnType<typeof EparOutputEl['getResults']>;
  private readonly endResultsPEL: ReturnType<typeof EparOutputEl['getResults']>;
  private readonly questions: IEpar['questions'];

  constructor(customer?: PMain.CustomerModel) {
    this.need = { ...needDefaultValue, ...customer?.Epar!.need };
    this.questions = { ...questionsDefaultValue, ...customer?.Epar!.questions };
    this.endResults = this.calcEndResults(this.need.rate, true);
    this.endResultsLivretA = this.calcEndResults(LIVRET_A_RATE, false);
    this.endResultsLDD = this.calcEndResults(LDD_RATE, false);
    this.endResultsPEL = this.calcEndResults(PEL_RATE, false);
  }

  // ! END
  private get defaultRounding() {
    return 0;
  }

  public get endYear() {
    return dayjs().add(this.nbYears, 'year').year();
  }

  // ! Result for Epar
  public get endCapital() {
    return pRound(this.endResults.endCapital, this.defaultRounding);
  }

  private get endVersWithTransfer() {
    return this.endResults.totalVers;
  }

  public get endVers() {
    return pRound(this.endVersWithTransfer, this.defaultRounding);
  }

  // ! Result for livret A
  public get endCapitalLivretA() {
    return pRound(this.endResultsLivretA.endCapital, this.defaultRounding);
  }

  private get endVersWithTransferLivretA() {
    return this.endResultsLivretA.totalVers;
  }

  public get endVersLivretA() {
    return pRound(this.endVersWithTransferLivretA, this.defaultRounding);
  }

  // ! Result for LDD
  public get endCapitalLDD() {
    return pRound(this.endResultsLDD.endCapital, this.defaultRounding);
  }

  private get endVersWithTransferLDD() {
    return this.endResultsLDD.totalVers;
  }

  public get endVersLDD() {
    return pRound(this.endVersWithTransferLDD, this.defaultRounding);
  }

  // ! Result for PEL
  public get endCapitalPEL() {
    return pRound(this.endResultsPEL.endCapital, this.defaultRounding);
  }

  private get endVersWithTransferPEL() {
    return this.endResultsPEL.totalVers;
  }

  public get endVersPEL() {
    return pRound(this.endVersWithTransferPEL, this.defaultRounding);
  }

  private calcEndResults(rate: number, promis: boolean) {
    const { need, commonInitElInput } = this;
    const { transfertVers, initialVers, regularVers, investProfile } = need;
    return EparOutputEl.getResults({
      ...commonInitElInput,
      transfertVers,
      initialVers,
      regularVers,
      investProfile,
      rate,
      promis,
    });
  }

  // ! DATES
  private get begDate() {
    return dayjs(this.questions.begDate ?? begDateDefault());
  }

  private get begMonth() {
    return this.begDate.startOf('month');
  }

  private get endMonth() {
    return this.endDate.endOf('month');
  }

  public get nbYears() {
    return this.questions.eparNbYears ?? 8;
  }

  private get endDate() {
    return dayjs().add(this.nbYears, 'years');
  }

  // !UTILS
  private get commonInitElInput() {
    const { endMonth, begMonth } = this;

    return {
      endMonth: endMonth.toDate(),
      begMonth: begMonth.toDate(),
    };
  }
}
