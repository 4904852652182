// REVENUE
// export const CHARGES_LIBERAL = 0.45 as const;

export const PASS = 41_136 as const;

// INVEST
export const EVOLVING_VERS_YEARLY_INCREASE = 0.2 as const;

export const STD_YEARLY_INCREASE = 0.02 as const;

export const PER_DEFAULT_ENTRY_FEES = 0.02 as const;

// Aviva fee
export const ASSUVIE_DEFAULT_ENTRY_FEES = 0.005 as const;

export const LIVRET_A_RATE = 2 as const;

export const LDD_RATE = 2 as const;

export const PEL_RATE = 1 as const;
