<template>
  <div class="bg-white">
    <div class="container py-32 flex-centered-content flex-col space-y-12">
      <div class="w-full flex-centered-conetnt flex-col space-y-4">
        <div class="flex flex-col space-y-2 text-blue-800 w-full">
          <p class="text-blue-800 text-sm uppercase">Calculateur</p>
          <h2 class="my-0 text-left">
            Le PER, une solution pour optimiser votre épargne et votre fiscalité
          </h2>
        </div>
        <p class="">
          Le PER vous permet d'épargner de façon mensuelle ou ponctuelle. En
          tant qu'indépendant, vos versements sont déductibles de vos revenus
          imposables (dans la limite de votre plafond "Madelin"). Calculez dès
          maintenant combien vous pouvez verser, et votre possible économie
          d'impôts immédiate.
        </p>
      </div>
      <div
        class="flex-centered-content flex-col w-full space-y-20 md:space-y-16 my-16 bg-purple-700 rounded-xl overflow-hidden"
      >
        <div
          class="flex flex-col md:flex-row w-full md:justify-between md:items-center bg-purple p-4 md:p-8 border-b border-purple-500"
        >
          <div class="order-2 md:order-1">
            <h3 class="text-purple-200 text-2xl md:text-left mb-1 md:mt-0">
              Calculez l'avantage fiscal de votre PER
            </h3>
            <p class="hidden md:block text-purple-100 text-sm">
              Faites glisser l'indicateur jusqu'à votre revenu annuel.
            </p>
          </div>
          <div
            class="flex-centered-content space-x-4 p-2 md:p-4 rounded-lg promis-purple-gradient order-1 md:order-2 shadow-inner"
          >
            <div
              class="bg-purple-100 border-2 border-purple-300 rounded-full w-12 h-12 p-2"
            >
              <Retr class="w-full h-full" />
            </div>
            <div class="md:w-40">
              <h4 class="text-purple-200 m-0">Promis.Retraite</h4>
              <p class="text-purple-100 text-xs">
                Calculateur conçu par l'équipe Promis
              </p>
            </div>
          </div>
        </div>
        <div class="p-4 md:p-8 w-full flex-centered-content flex-col space-y-8">
          <!-- Range -->
          <div action="" class="relative w-8/12 md:w-10/12">
            <div ref="rangeTooltip" class="absolute" :style="stylePosition">
              <div
                id="retrRangeTooltipContent"
                class="font-display text-center p-2 h-16 w-32 md:w-40 bg-orange-400 absolute bottom-3 rounded-md shadow-lg shadow-orange-500/20"
              >
                <label
                  for="revenueRange"
                  class="text-blue-800 text-sm font-semibold"
                >
                  Votre revenu
                </label>
                <div class="text-lg md:text-xl text-orange-700 font-bold">
                  {{ revenu }} <span class="text-xs md:text-sm">€/an</span>
                </div>
              </div>
            </div>
            <input
              id="revenueRange"
              ref="revenueRange"
              v-model="revenuRaw"
              name="revenueRange"
              type="range"
              class="w-full slider-input bg-purple shadow rounded-full border border-purple-700 relative cursor-pointer"
              :min="revenuMin"
              :max="revenuMax"
              :step="step"
            />
          </div>
          <!-- Simul -->
          <div
            class="w-full md:w-10/12 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8"
          >
            <div
              class="promis-bg-50 rounded-lg px-4 py-6 text-blue-800 w-full lg:w-1/2"
            >
              <i class="fa-solid fa-building-columns text-2xl"></i>
              <h4 class="text-sm lg:text-base">Versement déductible maximum</h4>
              <p class="text-xl lg:text-3xl font-bold font-display mt-2">
                {{ madelin }}<span class="text-base"> €/an</span>
              </p>
              <p class="text-sm">C'est votre plafond Madelin*</p>
            </div>
            <div class="flex-centered-content">
              <i
                class="fa-duotone fa-chevrons-right text-orange-400 text-3xl md:text-5xl rotate-90 md:rotate-0"
              ></i>
            </div>
            <div
              class="promis-bg-50 rounded-lg px-4 py-6 text-blue-800 w-full lg:w-1/2"
            >
              <i class="fa-solid fa-piggy-bank text-2xl"></i>
              <h4 class="text-sm lg:text-base">Économie d'impôts maximum**</h4>
              <p class="text-xl lg:text-3xl font-bold font-display mt-2">
                {{ taxSavings }}<span class="text-base"> €/an</span>
              </p>
            </div>
          </div>
          <div class="w-full md:w-10/12 text-purple-200 opacity-90 text-sm">
            <p>
              * Plafond calculé sur la base de votre revenu net : BNC ou BIC.
            </p>
            <p>
              ** Économie calculée sur la base de la tranche marginale
              d'imposition relative à votre revenu annuel, sur la base d'une
              part fiscale.
            </p>
          </div>
        </div>
      </div>

      <div class="flex-centered-content bg-orange-200 p-4 md:p-8 rounded-xl">
        <p>
          Vous pensez qu'un contrat PER peut répondre à votre besoin ? Allez
          plus loin et simulez l'économie d'impots totale ainsi que la rente à
          la retraite qu'un PER peut vous apporter.
          <a class="link text-purple" :onclick="simulUrl"
            >Simuler un PER dés maintenant.</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  calcMadelinRetr,
  calcTmiPercent,
  calcUnitMadelinSavings,
} from '@p/algo';
import { numToString } from '@p/utils';
import Retr from '@pw/assets/svg/Retr.svg';
import { ref } from 'vue';
import { getLink } from '../../utils/farmlinks';

// Raw values
const revenuMin = 40_000;
const revenuMax = 400_000;
const step = 1000;
const revenuRaw = ref(80_000);
const madelinRaw = computed(() => calcMadelinRetr(revenuRaw.value));
const tmi = computed(() => calcTmiPercent(revenuRaw.value));
const monthlyTaxSavingsRaw = computed(() =>
  calcUnitMadelinSavings(madelinRaw.value / 12, madelinRaw.value, tmi.value),
);
// Formatted values
const revenu = computed(() => numToString(revenuRaw.value, 0));
const madelin = computed(() => numToString(madelinRaw.value, 0));
const taxSavings = computed(() =>
  numToString(monthlyTaxSavingsRaw.value * 12, 0),
);

const stylePosition = computed(() => {
  const newValue =
    ((revenuRaw.value - revenuMin) * 100) / (revenuMax - revenuMin);
  const newPosition = 10 - newValue * 0.2;
  return `left: calc(${newValue}% + (${newPosition}px))`;
});

// Url
const simulUrl = `location.href="${getLink('app', 'simul', 'retraite')}"`;
</script>

<style>
.slider-input {
  appearance: none;
}

#retrRangeTooltipContent {
  left: 50%;
  transform: translate(-50%, 0);
}
#retrRangeTooltipContent:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  margin-top: -1px;
  @apply border-t-8 border-orange-400;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

/* Special styling for WebKit/Blink */
#revenueRange::-webkit-slider-thumb {
  -webkit-appearance: none;
  @apply w-5 h-6 rounded;
  @apply bg-orange;
  cursor: pointer;
  @apply -mt-1 -mb-1 shadow;
}

/* All the same stuff for Firefox */
#revenueRange::-moz-range-thumb {
  @apply w-5 h-6 rounded;
  @apply bg-orange;
  cursor: pointer;
  @apply -mt-1 -mb-1 shadow;
}
</style>
