import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat.js';
import Duration from 'dayjs/plugin/duration.js';
import LocalizedFormat from 'dayjs/plugin/localizedFormat.js';
import minMax from 'dayjs/plugin/minMax.js';
import Timezone from 'dayjs/plugin/timezone.js';
import Utc from 'dayjs/plugin/utc.js';
import Weekday from 'dayjs/plugin/weekday.js';
import localeFr from './dateFnFr.js';

export const setDayjs = () => {
  dayjs.extend(Utc);
  dayjs.extend(Timezone);
  dayjs.tz.setDefault('Europe/Paris');
  dayjs.extend(Duration);
  dayjs.extend(LocalizedFormat);
  dayjs.extend(Weekday);
  dayjs.extend(minMax);
  dayjs.locale(localeFr);
  dayjs.extend(customParseFormat);
};

export const firstDayOfNextMonth = (date?: Date) => {
  const d = date ?? new Date();
  return dayjs(d).startOf('month').add(1, 'month');
};

export const nbChildrenBelowAge = (
  age: number,
  // eslint-disable-next-line @typescript-eslint/ban-types
  childrenBirthDates: Array<Date | null | undefined>,
) => {
  const childrenBirthDatesBelowAge = childrenBirthDates.filter((bdate) => {
    if (!bdate) return true;
    return dayjs(bdate).year() > dayjs().year() - age;
  });
  return childrenBirthDatesBelowAge.length;
};

export const daysSince = (date: Parameters<typeof dayjs>[0]) => {
  const beginning = dayjs(date).startOf('day');
  return dayjs().startOf('day').diff(beginning, 'day');
};

export const yearsSince = (date: Date) => {
  const beginning = dayjs(date);
  return dayjs().diff(beginning, 'years', true);
};

export const dateStringified = (date: Date) => dayjs(date).format('DDMMYYYY');

const ACCEPTABLE_DELAY_BEFORE_END_OF_MONTH = 10 as const;

export const begDateDefault = () => {
  const nbDaysBeforeEndOfMonth = dayjs().endOf('month').diff(dayjs(), 'days');
  if (nbDaysBeforeEndOfMonth < ACCEPTABLE_DELAY_BEFORE_END_OF_MONTH)
    return dayjs().add(10, 'days').add(1, 'month').startOf('month');
  return dayjs().add(1, 'month').startOf('month');
};

export const formatLocaleDate = (date?: Date) => dayjs(date).format('LL');

export const getNextWeekDay = (dayjsDate: Dayjs) => {
  const currentWeekday = dayjsDate.weekday();
  if (currentWeekday < 5) return dayjsDate;
  return dayjsDate.add(1, 'week').startOf('week');
};

export const getPreviousWeekDay = (dayjsDate: Dayjs) => {
  const currentWeekday = dayjsDate.weekday();
  if (currentWeekday < 5) return dayjsDate;
  return dayjsDate.startOf('week').add(4, 'day');
};

export const begActivityDateDefault = () => dayjs().subtract(1, 'year');
