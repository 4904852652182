import { GeneralError } from '@feathersjs/errors';
import type { refInvestProfileKeys } from '@p/refenums';
import { consoleObject, lastEl, nbAry, sumAry } from '@p/utils';
import dayjs from 'dayjs';
import {
  PER_DEFAULT_ENTRY_FEES,
  STD_YEARLY_INCREASE as YEARLY_INCREASE,
} from '../constants';
import { RATE_EVOLUTION } from './refRetrData/rateEvolution';
import { CalcRetrRente } from './refRetrData/retrOutputRente.calc';

export interface IInitialEl {
  retrMonth: Date;
  begMonth: Date;
  transfertVers: number;
  initialVers: number;
  regularVers: number[];
  investProfile: typeof refInvestProfileKeys[number];
  rate: number;
  birthYear: number;
  endAge: number;
  entryFees?: number;
  madelinRetrCurrent: number;
}

const interestsPartOnRegVers = (n: number) => {
  const nAry = nbAry(n);
  return sumAry(nAry.map((i) => (n - i) / 12)) / 12;
};

const interestsPartOnRegVersFullYear = interestsPartOnRegVers(12);

interface IElRes {
  nextBegMonth: Date;
  endCapital: number;
  regMonthlyVers: number;
  totalVers: number;
  totalMadelinVers: number;
  i: number;
  isLastPeriod: boolean;
  madelinRetrCurrent: number;
}
export class RetrOutputEl {
  public i: number;
  public res!: IElRes;

  static getResults(initEl: IInitialEl, logValues = false) {
    const resAry = this.build(initEl, [], 0, logValues);

    const endRes = lastEl(resAry) ?? {
      endCapital: 0,
      totalVers: 0,
      totalMadelinVers: 0,
    };

    const { rente } = new CalcRetrRente(
      initEl.birthYear,
      initEl.endAge,
      endRes.endCapital,
    );
    return { ...endRes, rente };
  }

  private static build(
    initEl: IInitialEl,
    previousAry: IElRes[],
    i: number,
    logValues = false,
  ): IElRes[] {
    if (i > 50) throw new GeneralError('Recursion Error in RetrOuputEl Build');
    const newEl = new this(initEl, lastEl(previousAry));
    // eslint-disable-next-line ban/ban
    if (logValues) consoleObject(newEl.previousEl ?? newEl.initEl);
    const newAry = [...previousAry, newEl.res];
    if (newEl.isLastPeriod) return newAry;
    return this.build(initEl, newAry, i + 1, logValues);
  }

  private constructor(
    private readonly initEl: IInitialEl,
    private readonly previousEl?: IElRes,
  ) {
    this.i = this.isInitial ? 0 : this.previousEl!.i + 1;
    this.calcResults();
  }

  private calcResults() {
    const {
      nextBegMonth,
      endCapital,
      regMonthlyVers,
      endVers: totalVers,
      endMadelinVers: totalMadelinVers,
      i,
      isLastPeriod,
      madelinRetrCurrent,
    } = this;
    this.res = {
      nextBegMonth: nextBegMonth.toDate(),
      endCapital,
      regMonthlyVers,
      totalVers,
      totalMadelinVers,
      i,
      isLastPeriod,
      madelinRetrCurrent,
    };
  }

  private get isInitial() {
    return !this.previousEl;
  }

  // ! DATES
  private get nextBegMonth() {
    return this.begMonth.add(1, 'year');
  }

  private get isLastPeriod() {
    return this.retrMonth < this.nextBegMonth;
  }

  private get retrMonth() {
    return dayjs(this.initEl.retrMonth);
  }

  private get begMonth() {
    const begMonthDate = this.isInitial
      ? this.initEl.begMonth
      : this.previousEl!.nextBegMonth;
    return dayjs(begMonthDate);
  }

  private get nbRegularVers() {
    if (!this.isLastPeriod) return 12;
    return this.retrMonth.diff(this.begMonth, 'months') + 1;
  }

  private get entryCoeff() {
    return 1 - (this.initEl.entryFees ?? PER_DEFAULT_ENTRY_FEES) / 100;
  }

  // ! CAPITAL
  private get begVers() {
    return this.isInitial
      ? this.initEl.transfertVers + this.initEl.initialVers
      : this.previousEl!.totalVers;
  }

  private get endVers() {
    return this.begVers + this.totalRegVersRaw;
  }

  private get begCapital() {
    return this.isInitial
      ? this.initEl.transfertVers + this.initEl.initialVers * this.entryCoeff
      : this.previousEl!.endCapital;
  }

  private get endCapital() {
    return (
      this.begCapital +
      this.interestsOnBegCapital +
      this.totalRegVersAfterEntryFee +
      this.interestsOnRegVers
    );
  }

  private get interestsOnBegCapital() {
    return this.begCapital * this.currentRate * (this.nbRegularVers / 12);
  }

  private get totalRegVersAfterEntryFee() {
    return this.totalRegVersRaw * this.entryCoeff;
  }

  private get totalRegVersRaw() {
    return this.nbRegularVers * this.regMonthlyVers;
  }

  private get interestsOnRegVers() {
    const n = this.nbRegularVers;
    if (!this.isLastPeriod)
      return (
        interestsPartOnRegVersFullYear *
        this.currentRate *
        this.totalRegVersAfterEntryFee
      );
    return (
      interestsPartOnRegVers(n) *
      this.currentRate *
      this.totalRegVersAfterEntryFee
    );
  }

  private get regMonthlyVers() {
    if (this.isInitial) return this.initEl.regularVers[0] ?? 0;
    return (
      this.initEl.regularVers[this.i] ??
      this.previousEl!.regMonthlyVers * (1 + YEARLY_INCREASE)
    );
  }

  private get nbYearsBeforeRetr() {
    return this.retrMonth.diff(this.begMonth, 'years');
  }

  private get currentRate() {
    if (this.nbYearsBeforeRetr > 15) return this.initEl.rate / 100;
    return this.lastYearsRate / 100;
  }

  private get lastYearsRate() {
    const el = RATE_EVOLUTION[this.nbYearsBeforeRetr.toString()];
    if (!el) return 0;
    if (this.initEl.investProfile !== 'perso')
      return el[this.initEl.investProfile];
    return (el.persoEvolve * this.initEl.rate) / 100;
  }

  // MADELIN LOGIC
  private get madelinRetrCurrent() {
    if (this.isInitial) return this.initEl.madelinRetrCurrent;
    return this.previousEl!.madelinRetrCurrent * (1 + YEARLY_INCREASE);
  }

  private get endMadelinVers() {
    //! Without initial (specific calculation handled in retrOutput Calc)
    if (this.isInitial)
      return (
        Math.min(this.madelinRetrCurrent / 12, this.regMonthlyVers) *
        this.nbRegularVers
      );
    return (
      this.previousEl!.totalMadelinVers +
      Math.min(this.madelinRetrCurrent, this.totalRegVersRaw)
    );
  }
}
