export const lastEl = <T>(ary: T[]) => {
  // eslint-disable-next-line unicorn/no-useless-undefined
  if (ary.length === 0) return undefined;
  return ary[ary.length - 1];
};

export const dedupeAry = <T>(ary: T[]): T[] => [...new Set(ary)];

export const addElToAry = <T>(el: T, ary: T[] = []) => dedupeAry([el, ...ary]);

export function isIncludedInAry<T, U extends T>(
  ary: readonly U[],
  el: T,
): el is U {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
  return ary.includes(el as any);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function handleFnForAryOrEl<T, R = any>(
  aryOrEl: T | T[],
  fn: (el: T) => R,
): R | R[] {
  if (Array.isArray(aryOrEl)) {
    return aryOrEl.map((el) => fn(el));
  }

  return fn(aryOrEl);
}

export const splitStringToAry = (str?: string, separator = ' ') => {
  if (!str) return [];
  return str.split(separator);
};

export const sumAry = (ary: number[]): number => ary.reduce((a, b) => a + b, 0);

export const nbAry = (n: number) => Array.from({ length: n }, (_n, i) => i);

export const chunkAry = <T>(ary: T[], chunkSize: number): T[][] => {
  const chunks: T[][] = [];
  let i = 0;
  const n = ary.length;
  while (i < n) {
    chunks.push(ary.slice(i, (i += chunkSize)));
  }

  return chunks;
};
