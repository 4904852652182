import type { DeepNullable, DeepPartial } from '@p/utils';

const getMadelinCap = (
  instype: IInsTypes,
  madelinPrevSante = 0 as TypedNumber<'year'>,
  madelinRetr = 0 as TypedNumber<'year'>,
) => {
  if (instype === 'Prev' || instype === 'Sante') return madelinPrevSante;
  if (instype === 'Retr') return madelinRetr;
  return 0 as TypedNumber<'year'>;
};

export const calcUnitMadelinSavings = (
  price = 0 as TypedMonthly,
  madelin = 0 as TypedNumber<'year'>,
  tmi = 0,
) => {
  const eligiblePrice = Math.min(price, madelin / 12);
  return ((eligiblePrice * tmi) / 100) as TypedMonthly;
};

export const calcMadelinSavings = (
  contract?:
    | PMain.ContractModel
    | DeepPartial<DeepNullable<PMain.ContractModel>>,

  customer?: PMain.CustomerModel | null,
) => {
  if (
    !customer?.profile?.revenue ||
    !contract ||
    !contract.instype ||
    !contract.price
  )
    return 0;
  const { tmi, madelinPrevSante, madelinRetr } = customer.profile.revenue;
  const priceEligibleMonthly =
    contract?.insContractInfos?.priceEligibleMadelin ?? contract.price;
  const madelinCap = getMadelinCap(
    contract.instype,
    madelinPrevSante,
    madelinRetr,
  );

  return calcUnitMadelinSavings(priceEligibleMonthly, madelinCap, tmi);
};
