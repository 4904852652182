import Engine from 'publicodes';
import {
  entEurlSituation,
  entSasuSituation,
  type IEntInputs,
} from './rules/entreprise';
import type { IProfLibInputs } from './rules/profLib';
import { profLibSituation } from './rules/profLib';
import { getConsolidatedRules } from './rules/promisRules';

const silentLogging = true;

// eslint-disable-next-line sonarjs/cognitive-complexity
const getEngineOptions = () => {
  const options: Partial<Engine['options']> = {
    getUnitKey(unit) {
      if (unit === 'ans') return 'an';
      if (unit === 'trimestres') return 'trimestre';
      if (unit === 'trimestres validés') return 'trimestre validé';
      if (unit === 'semaines') return 'semaine';
      if (unit === 'jours') return 'jour';
      if (unit === 'jours ouvrés') return 'jour ouvré';
      if (unit === 'heures') return 'heure';
      if (unit === 'points') return 'point';
      if (unit === 'parts') return 'part';
      if (unit === 'manifestations') return 'manifestation';
      if (unit === 'titres-restaurant') return 'titre-restaurant';
      if (unit === 'employés') return 'employé';
      if (unit === 'enfants') return 'enfant';
      if (unit === 'décimales') return 'décimale';

      return unit;
    },
  };
  if (silentLogging)
    options.logger = {
      log(_msg) {},
      warn(_msg) {},
      error(msg) {
        // eslint-disable-next-line no-console
        console.error(msg);
      },
    };
  return options;
};

export const modeleSocialEngine = new Engine(
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  getConsolidatedRules(),
  getEngineOptions(),
);

export const transformInputsSituation = <T extends IStringRecord>(
  inputs: T,
): T => {
  const keys = Object.keys(inputs);
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const transformedInputs = {} as T;
  keys.forEach((key: keyof T) => {
    const value = inputs[key];
    if (typeof value === 'string' && value !== 'oui' && value !== 'non')
      transformedInputs[key] = `"${value}"` as T[keyof T];
    else transformedInputs[key] = value;
  });
  return transformedInputs;
};

export const getSituation = (entType: 'sasu' | 'eurl' | 'profLib') => {
  if (entType === 'sasu') return entSasuSituation;
  if (entType === 'eurl') return entEurlSituation;
  return profLibSituation;
};

const transcoReturn = {
  net: 'promis . net',
  ca: "entreprise . chiffre d'affaires",
  brut: 'dirigeant . rémunération . totale',
} as const;

export const calcMonEntResults = (
  inputs: IEntInputs | IProfLibInputs,
  entType: 'sasu' | 'eurl' | 'profLib' = 'profLib',
  returnType: 'net' | 'ca' | 'brut' = 'net',
) => {
  const inputSituation = getSituation(entType);

  const transformedInputs = transformInputsSituation({
    ...inputSituation,
    ...inputs,
  });
  const situation = modeleSocialEngine.setSituation(transformedInputs);
  const toEval = transcoReturn[returnType];

  return situation.evaluate(toEval).nodeValue as number;
};
