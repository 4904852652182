/* eslint-disable @typescript-eslint/naming-convention */
import { refInvestProfile } from '@p/refenums';

type IRateEvolution = Record<
  string,
  {
    conservative: number;
    balanced: number;
    dynamic: number;
    persoEvolve: number;
  }
>;
// Source: https://docs.google.com/spreadsheets/d/1R9YIPVZ6G_GKW6R3o8GbkdB35i4CISJbtr57QBnG0O8/edit#gid=44679959

// ! WARNING > VALUE15 (= 15+) should be equal to the rate in invest.enums

export const RATE_EVOLUTION: IRateEvolution = {
  '0': {
    conservative: 1.5,
    balanced: 1.5,
    dynamic: 1.5,
    persoEvolve: 47.17,
  },
  '1': {
    conservative: 1.614,
    balanced: 1.662,
    dynamic: 1.705,
    persoEvolve: 52.264,
  },
  '2': {
    conservative: 1.723,
    balanced: 1.805,
    dynamic: 1.932,
    persoEvolve: 56.761,
  },
  '3': {
    conservative: 1.861,
    balanced: 1.9,
    dynamic: 2.095,
    persoEvolve: 59.748,
  },
  '4': {
    conservative: 1.918,
    balanced: 2.019,
    dynamic: 2.234,
    persoEvolve: 63.491,
  },
  '5': {
    conservative: 1.974,
    balanced: 2.145,
    dynamic: 2.365,
    persoEvolve: 67.453,
  },
  '6': {
    conservative: 2.07,
    balanced: 2.242,
    dynamic: 2.497,
    persoEvolve: 70.503,
  },
  '7': {
    conservative: 2.166,
    balanced: 2.338,
    dynamic: 2.681,
    persoEvolve: 73.522,
  },
  '8': {
    conservative: 2.166,
    balanced: 2.407,
    dynamic: 2.865,
    persoEvolve: 75.692,
  },
  '9': {
    conservative: 2.166,
    balanced: 2.452,
    dynamic: 3,
    persoEvolve: 77.107,
  },
  '10': {
    conservative: 2.166,
    balanced: 2.587,
    dynamic: 3.135,
    persoEvolve: 81.352,
  },
  '11': {
    conservative: 2.215,
    balanced: 2.726,
    dynamic: 3.27,
    persoEvolve: 85.723,
  },
  '12': {
    conservative: 2.264,
    balanced: 2.931,
    dynamic: 3.36,
    persoEvolve: 92.17,
  },
  '13': {
    conservative: 2.313,
    balanced: 3.09,
    dynamic: 3.36,
    persoEvolve: 97.17,
  },
  '14': {
    conservative: 2.358,
    balanced: 3.18,
    dynamic: 3.36,
    persoEvolve: 100,
  },
  '15': {
    conservative: refInvestProfile.conservative.rate!,
    balanced: refInvestProfile.balanced.rate!,
    dynamic: refInvestProfile.dynamic.rate!,
    persoEvolve: 100,
  },
} as const;
